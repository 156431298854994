import { Component, AfterViewInit, ViewChild, ElementRef, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { TranslateService } from "@ngx-translate/core";
import { EIncidentType, remapTypeIn } from "src/app/concepts/incidents/incident-types";
import { IFleetImageHighlight } from "src/app/concepts/api/lightmetrics/fleets/incidents/get-fleet-highlights";


@Component({
    selector: 'image-viewer-dialog',
    templateUrl: 'image-viewer-dialog.component.html',
    styleUrls: ['./image-viewer-dialog.component.scss']
})


export class ImageViewerDialogComponent implements AfterViewInit {
    private map: google.maps.Map;
    private lat = 25.11310843;
    private lng = 55.2425005;

    public language: string = this.translate.currentLang;

    constructor(
        public dialogRef: MatDialogRef<ImageViewerDialogComponent>,
        private translate: TranslateService,
        @Inject(MAT_DIALOG_DATA) public data: IFleetImageHighlight) {
        this.lat = this.data.position.lat;
        this.lng = this.data.position.lng;
        this.translate.onLangChange.subscribe((event) => {
            this.language = event.lang;
        });
    }

    @ViewChild('mapContainer', { static: false }) gmap: ElementRef;

    private coordinates = new google.maps.LatLng(this.data.position.lat, this.data.position.lng);

    private mapOptions: google.maps.MapOptions = {
        center: this.coordinates,
        zoom: 16
    };

    ngOnInit() {
        this.data.eventType = this.translate.instant(EIncidentType[remapTypeIn(this.data.eventType)]);
    }

    ngAfterViewInit() {
        this.mapInitializer();
    }

    mapInitializer() {
        this.map = new google.maps.Map(this.gmap.nativeElement, this.mapOptions);
        const marker = new google.maps.Marker({
            position: this.coordinates,
            map: this.map,
        });
        marker.setMap(this.map);
    }

    onNoClick(): void {
        this.dialogRef.close();
    }

    public onImageLoad(event: any) {
        console.log('Highlights Dialog - onImageLoad', event);
    }
}