import { Injectable } from '@angular/core';
import { from, lastValueFrom, Observable } from 'rxjs';
import { eventsPath, vikingAdminFleetsPath, vikingAdminUserPortalPath, vikingFleetsPath, vikingGeofenceAdminPath, vikingGeofenceEventsPath } from 'src/app/concepts/utils/api/api-utils';
import { getParamsFleetTrips, getParamsFleetTripsV2, IReqFleetTrips, IReqFleetTripsV2 } from 'src/app/concepts/api/lightmetrics/fleets/trips/get-fleet-trips';
import { IReqFleetViolations, IResFleetViolations, getParamsFleetViolations } from 'src/app/concepts/api/lightmetrics/fleets/incidents/get-fleet-violations';
import { getParamsFleetDrivers, IReqFleetDrivers, IResFleetDrivers } from 'src/app/concepts/api/lightmetrics/fleets/drivers/get-fleet-drivers';
import { getParamsAggregateFleetTrips, IReqAggregateFleetTrips } from 'src/app/concepts/api/lightmetrics/fleets/trips/get-aggregate-fleet-trips';
import { getParamsFleetCoaching, IReqFleetCoaching } from 'src/app/concepts/api/lightmetrics/fleets/coaching/get-fleet-coaching';
import { defaultReqDriverTripDetails, getParamsDriverTripDetails, IReqDriverTripDetails } from 'src/app/concepts/api/lightmetrics/fleets/drivers/get-driver-trip-details';
import { getParamsAggregateDriverTrips, IReqAggregateAssetTrips, IReqAggregateDriverTrips } from 'src/app/concepts/api/lightmetrics/fleets/drivers/get-driver-aggregate-trips';
import { IReqDriverEventDetails, getParamsDriverEventDetails } from 'src/app/concepts/api/lightmetrics/fleets/drivers/get-driver-event-details';
import { getParamsOngoingTrips, IReqOngoingTrips } from 'src/app/concepts/api/lightmetrics/fleets/trips/get-ongoing-trips';
import { S3Service } from './s3-service';
import axios from 'axios';
import { getParamsChangeDriverId, getParamsVikingFleetDrivers, IReqChangeDriverIdOptions, IReqVikingFleetDriver, IResVikingFleetDriver } from 'src/app/concepts/api/viking/viking-driver';
import { HttpClient } from '@angular/common/http';
import { getParamsFleetAssets, IReqFleetAssets, IResAdminAssets, IResAdminAssetsRow } from 'src/app/concepts/api/lightmetrics/fleets/assets/get-fleet-assets';
import { CognitoService } from './cognito-service';
import { IPortalUser, UserService } from './user-service';
import { IDeviceState } from 'src/app/concepts/devices/devices';
import { outgoingEventTypeMap } from 'src/app/concepts/incidents/incident-types';
import { environment } from 'src/environments/environment';
import moment from 'moment';
import { IGeofence, IGeofenceEvent } from 'src/app/concepts/api/viking/geofence';

export const getAdminFleets = async (fleetId: string, path: string, params?:string) : Promise<any> => {
  console.log('_getFleets 1 - fleetId', fleetId)
  const url = `${vikingAdminFleetsPath(path, fleetId)}${params ? params : ''}`
  try {
    const res = await axios.get(url, {
      method: 'GET',
      headers: {
        // "X-Api-Key": environment.viking.apiKey,
        'Content-Type': 'application/json'
      }
    })
    console.log('_getFleets 2 res', res)

    let json = await res.data
    console.log('_getFleets api response', json)

    return json

  } catch(e) {
    console.warn('Error node.fetch:', e, 'from', url)
    return null
  }
}

export const getAdminUserPortal = async (path: string, params?:string) : Promise<any> => {
  console.log('getAdminUserPortal 1')
  const url = `${vikingAdminUserPortalPath(path)}${params ? params : ''}`
  try {
    const res = await axios.get(url, {
      method: 'GET',
      headers: {
        // "X-Api-Key": environment.viking.apiKey,
        'Content-Type': 'application/json',
        //'ngrok-skip-browser-warning': 'true'
      }
    })
    console.log('getAdminPortal 2 res', res)

    let json = await res.data
    console.log('getAdminPortal api response', json)

    return json

  } catch(e) {
    console.warn('Error node.fetch:', e, 'from', url)
    return null
  }
}

@Injectable({
  providedIn: 'root',
})
export class VikingAPIService {

  private _user:IPortalUser;

  constructor(
    private _s3: S3Service,
    private _http: HttpClient,
    private _userService: UserService
  ) {
    console.log('VIKINGAPI: constructor, user', this._user)
  }

  private async _getFleets(path: string, params?:string) : Promise<any> {
    this._user = this._userService.getUser()
    console.log('_getFleets 1 real, user', this._user)
    const url = `${vikingFleetsPath(path, this._user.fleetId)}${params ? params : ''}`
    try {
      const res = await axios.get(url, {
        method: 'GET',
        headers: {
          // "X-Api-Key": environment.viking.apiKey,
          'Content-Type': 'application/json',
          //'ngrok-skip-browser-warning': 'true'
        }
      })
      console.log('_getFleets 2 res', res)

      let json = await res.data
      console.log('_getFleets api response', json)

      return json

    } catch(e) {
      console.warn('Error node.fetch:', e, 'from', url)
      return null
    }
  }

  private async _getTripDetailsV2(path: string, params?:string) : Promise<any> {
    const url = `${environment.viking.dashcamUrl}${path}${params ? params : ''}`
    try {
      const res = await axios.get(url, {
        method: 'GET',
        headers: {
          // "X-Api-Key": environment.viking.apiKey,
          'Content-Type': 'application/json'
        }
      })
      console.log('_getTripDetailsV2 2 res', res)

      let json = await res.data
      console.log('_getTripDetailsV2 api response', json)

      return json

    } catch(e) {
      console.warn('Error node.fetch:', e, 'from', url)
      return null
    }
  }

  private async _getVikingV2Fleets(path: string, params?:string) : Promise<any> {
    this._user = this._userService.getUser()
    //console.log('_getFleets 1 real, user', this._user)
    const url = `${environment.viking.vikingUrl}fleet/${this._user.fleetId}/${path}${params ? params : ''}`
    try {
      const res = await axios.get(url, {
        method: 'GET',
        headers: {
          // "X-Api-Key": environment.viking.apiKey,
          'Content-Type': 'application/json',
          //'ngrok-skip-browser-warning': 'true'
        }
      })
      console.log('_getFleetsV2 2 res', res)

      let json = await res.data
      console.log('_getFleetsV2 api response', json.data)

      return json.data

    } catch(e) {
      console.warn('Error node.fetch:', e, 'from', url)
      return null
    }
  }

  private async _deleteFleets(path: string, params?:string) : Promise<any> {
    const url = `${vikingFleetsPath(path, this._user.fleetId)}${params ? params : ''}`
    try {
      const res$ = this._http.delete(url)
      const res = await lastValueFrom(res$)
      console.log('_deleteFleets res', res)

      console.log('_deleteFleets api response', res)

      return res

    } catch(e) {
      console.warn('Error node.fetch:', e, 'from', url)
      return null
    }
  }

  // Original (Axios)
  // private async _deleteFleets(path: string, params?:string) : Promise<any> {
  //   const url = `${vikingFleetsPath(path)}${params ? params : ''}`
  //   try {
  //     const res = await axios.delete(url, {
  //       method: 'DELETE',
  //     })
  //     console.log('_deleteFleets res', res)

  //     let json = await res.data
  //     console.log('_deleteFleets api response', json)

  //     return json

  //   } catch(e) {
  //     console.warn('Error node.fetch:', e, 'from', url)
  //     return null
  //   }
  // }

// STUBS

  public getConfiguration() : Observable<any> { return null }
  public getTrips(options: IReqFleetTrips) : Observable<any> { return from(this._getFleets('trips', getParamsFleetTrips(options))) }
  public getTripsStops(options: IReqFleetTrips) : Observable<any> { return from(this._getFleets('trips/stops', getParamsFleetTrips(options))) }

  public getTripsV2(options: IReqFleetTripsV2) : Observable<any> { return from(this._getVikingV2Fleets('trips', getParamsFleetTripsV2(options))) }

	public getViolations(options: IReqFleetViolations) : Observable<any> { return from(this._getFleets('violations', getParamsFleetViolations(options))) }
  public getViolationsV2(options: IReqFleetViolations): Observable<any> { return from(this._getVikingV2Fleets('trip/:tripId/violation/:violationId/videos/list', getParamsFleetViolations(options))) }

  // public getDriverTrips(driverId: string, options: IReqDriverTrips) : Observable<any> { return from(this._getFleets(`drivers/${driverId}/trips`, getParamsDriverTrips(options))) }
  public getDriverViolations(driverId: string, options: IReqFleetViolations) : Observable<any> { return from(this._getFleets(`drivers/${driverId}/violations`, getParamsFleetViolations(options))) }
  public getAssetViolations(assetId: string, options: IReqFleetViolations) : Observable<any> { return from(this._getFleets(`assets/${assetId}/violations`, getParamsFleetViolations(options))) }
  public getAggregateDriverTrips(driverId: string, options: IReqAggregateDriverTrips) : Observable<any> { return from(this._getFleets(`drivers/${driverId}/aggregate`, getParamsAggregateDriverTrips(options))) }
  public getAggregateAssetTrips(assetId: string, options: IReqAggregateAssetTrips) : Observable<any> { return from(this._getFleets(`assets/${assetId}/aggregate`, getParamsAggregateDriverTrips(options))) } // not typo, same opts as driver
  public getFleetCoaching(options: IReqFleetCoaching) : Observable<any> { return from(this._getFleets('coaching-events', getParamsFleetCoaching(options))) }
  public getDriverEventDetails(driverId: string, tripId: string, eventIndex: number, options: IReqDriverEventDetails) : Observable<any> { return from(this._getFleets(`drivers/${driverId}/trips/${tripId}/events/${eventIndex}`, getParamsDriverEventDetails(options))) }
  public getOngoingTrips(options: IReqOngoingTrips) : Observable<any> { return from(this._getFleets('ongoing-trips', getParamsOngoingTrips(options))) }

  public async getVikingDataLabellingEvents(): Promise<IResFleetViolations> {
    const url = environment.viking.baseUrl + 'data-labelling/violations'
    try {
      const res = await axios.get(url, {
        method: 'GET',
        headers: {
          // "X-Api-Key": environment.viking.apiKey,
          'Content-Type': 'application/json',
          //'ngrok-skip-browser-warning': 'true' 
        }
      })
      console.log('VIKINGAPI:getDataLabellingViolations res', res.data)
      return res?.data
    } catch (err) {
      console.log(err)
    }
  }

  public async updateVikingDataLabellingEvent(tripId: string, eventIndex: number, eventType: string, tags: any): Promise<IResFleetViolations> {
    const url = environment.viking.baseUrl + `data-labelling/violations/${tripId}/${eventIndex}`
    const body = {
      "eventType": eventType,
      "tags": tags
    }
    try {
      const res = await axios.post(url, body, {
        method: 'POST',
        headers: {
          // "X-Api-Key": environment.viking.apiKey,
          'Content-Type': 'application/json',
          //'ngrok-skip-browser-warning': 'true' 
        }
      })
      console.log('VIKINGAPI:updateVikingDataLabellingEvent res', res.data)
      return res?.data
    } catch (err) {
      console.log(err)
    }
  }

  public async updateVikingDataLabellingEventMultipleEvents(tripId: string, eventIndex: number, eventType: string[], tags: any): Promise<IResFleetViolations> {
    const url = environment.viking.baseUrl + `data-labelling/violations/${tripId}/${eventIndex}`
    const body = {
      "eventType": eventType,
      "tags": tags
    }
    try {
      const res = await axios.post(url, body, {
        method: 'POST',
        headers: {
          // "X-Api-Key": environment.viking.apiKey,
          'Content-Type': 'application/json',
          //'ngrok-skip-browser-warning': 'true'
        }
      })
      console.log('VIKINGAPI:updateVikingDataLabellingEvent res', res.data)
      return res?.data
    } catch (err) {
      console.log(err)
    }
  }
  public async setVikingDataLabellingEventState(tripId: string, eventIndex: number, state: string): Promise<boolean> {
    const url = `${environment.viking.baseUrl}data-labelling/violations/${tripId}/${eventIndex}?Content-Type=application/json`;
    // const url = `${environment.viking.baseUrl}data-labelling/violations/${tripId}/${eventIndex}?ngrok-skip-browser-warning=true&Content-Type=application/json`;

    try {
      const formData = new FormData();
      formData.append('dataLabellingState', state);
      const res = navigator.sendBeacon(url, formData);

      console.log('VIKINGAPI:updateVikingDataLabellingEvent res', res);
      return res;
    } catch (err) {
      console.error(err);
      return false;
    }
  }

  public async getAllFleetGeofences(skip? : number, limit? :number) : Promise<IGeofence[]|undefined>{
    const url = vikingGeofenceAdminPath(this._user.fleetId, skip, limit)
    try {
      const res = await axios.get(url, {
        method: 'GET',
        headers: {
          // "X-Api-Key": environment.viking.apiKey,
          'Content-Type': 'application/json'
        }
      })
      console.log('VIKINGAPI:getAllFleetGeofences res', res.data)
      return res?.data
    } catch(err) {
      console.log(err)
    }
  }

  public async getAllFleetGeofenceEvents(skip? : number, limit? :number, from? :string, to? :string) : Promise<IGeofenceEvent[]|undefined>{
    const url = vikingGeofenceEventsPath(this._user.fleetId, skip, limit) + `&from=${from}&to=${to}`
    try {
      const res = await axios.get(url, {
        method: 'GET',
        headers: {
          // "X-Api-Key": environment.viking.apiKey,
          'Content-Type': 'application/json'
        }
      })
      console.log('VIKINGAPI:getAllFleetGeofenceEvents res', res.data)
      return res?.data
    } catch(err) {
      console.log(err)
    }
  }

  public getDriverTripDetails(driverId: string, tripId: string, options: IReqDriverTripDetails) : Promise<any> {
    return this._getFleets(`drivers/${driverId}/trips/${tripId}`, getParamsDriverTripDetails(options))
  }

  public getTripDetailsV2(tripId: string,fleetId: string, options: IReqDriverTripDetails) : Promise<any> {
    return this._getTripDetailsV2(`trip/${tripId}/${fleetId}/state`, getParamsDriverTripDetails(options))
  }

  // This replicates the original LM front end requirement
  public getDrivers(options: IReqFleetDrivers) : Observable<IResFleetDrivers> {
    return from(this._getFleets('drivers', getParamsFleetDrivers(options)))
  }

  // This replicates the original LM front end requirement
  public getAssets(options: IReqFleetAssets) : Observable<any> {
    return from(this._getFleets('assets', getParamsFleetAssets(options)))
  }

  // This is not a replacement for the original LM getAssets, this is very similar
  // however it returns just an array of assets in a fleet
  public async getAllFleetAssets() : Promise<string[]|undefined>{
    const url = vikingAdminFleetsPath('assets', this._user.fleetId)
    try {
      const res = await axios.get(url, {
        method: 'GET',
        headers: {
          // "X-Api-Key": environment.viking.apiKey,
          'Content-Type': 'application/json'
        }
      })
      console.log('VIKINGAPI:getAllFleetAssets res', res.data)
      if(res?.data?.length > 0) {
        // const ret = (res.data as IResAdminAssetsRow[]).map<string>((row:IResAdminAssetsRow) => {
        //   return row.assetName ? row.assetName : row.assetId
        // })
        console.log('VIKINGAPI:geAllFleetAssets ret', res.data)
        return res.data
      }
    } catch(err) {
      console.log(err)
    }
  }

  // This is not a replacement for the original LM getAssets, this is very similar
  // however it returns just an array of assets (as objects) in a fleet
  public async getAllFleetAssetsFullV1() : Promise<IResAdminAssetsRow[]|undefined>{
    const url = vikingAdminFleetsPath('assets', this._user.fleetId)
    try {
      const res = await axios.get(url, {
        method: 'GET',
        headers: {
          // "X-Api-Key": environment.viking.apiKey,
          'Content-Type': 'application/json'
        }
      })

      let json = await res.data
      console.log('VIKINGAPI:getAllFleetAssets res', json)
      return json

    } catch(e) {
      console.warn('Error node.fetch:', e, 'from', url)
      return null
    }
  }

  // This returns the Viking Driver model
  public getVikingDriver(options: IReqVikingFleetDriver) : Observable<IResVikingFleetDriver> {
    return from(getAdminFleets(this._user.fleetId, `drivers/${options.driverId}`))
  }

  // This returns all Viking Drivers
  public listVikingDrivers() : Observable<IResVikingFleetDriver[]> {
    return from(getAdminFleets(this._userService.getUser().fleetId, 'drivers'))
  }

  public listVikingDriversV2() : Observable<IResVikingFleetDriver[]> {
    return from(this._getVikingV2Fleets('drivers'))
  }

  public listVikingAssetsV2() : Observable<IResAdminAssetsRow[]> {
    return from(this._getVikingV2Fleets('assets'))
  }

  // This is what is called to change the driver ID on Trip, Trip Details, and Violations
  public changeDriverId(driverId: string, tripId: string, options: IReqChangeDriverIdOptions) {
    return from(this._getFleets(`drivers/${driverId}/trips/${tripId}/update-driver-id`, getParamsChangeDriverId(options)))
  }

  // This is what deletes a false positive violation
  public async deleteViolation(driverId: string, tripId: string, eventIndex: number, eventType: string) {
    try {
      const res = await this._deleteFleets(`drivers/${driverId}/trips/${tripId}/violations/${eventIndex}/${outgoingEventTypeMap[eventType]}`)
    } catch(err) {
      console.log(err)
    }

  }

  public getAggregateFleetTrips(options: IReqAggregateFleetTrips) : Observable<any> {
    return from(this._getFleets('aggregate', getParamsAggregateFleetTrips(options)))
  }

  public getAggregateFleetTripsV2(options: IReqAggregateFleetTrips) : Observable<any> {
    return from(this._getVikingV2Fleets('trip/stats', getParamsAggregateFleetTrips(options)))
  }

  public getAggregateFleetViolationsV2(options: IReqAggregateFleetTrips) : Observable<any> {
    return from(this._getVikingV2Fleets('violation/stats', getParamsAggregateFleetTrips(options)))
  }


  // Returns only the active fleet devices
  public async getDevices() : Promise<IDeviceState[] | undefined> {
    return await this._getFleets('devices')
  }

  public async getDevicesV2() : Promise<any[]> {
    return await this._getVikingV2Fleets('heartbeat')
  }

  public async setFleetId(fleetId: string) {
    //this._user.fleetId = fleetId
    this._user = this._userService.getUser()
    console.log('setFleetId 1 real, user', this._user)
    const url = `${vikingAdminUserPortalPath('user/' + this._user.email +'/attributes/update')}`
    const body = {
      "fleetId": fleetId
  }
    try {
      const res = await axios.post(url, body, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        }
      })
      console.log('setFleetId 2 res', res)

      let json = await res.data
      console.log('setFleetId api response', json)

      return json

    } catch(e) {
      console.warn('Error node.fetch:', e, 'from', url)
      return null
    }
  }

  public async createFleet(fleetId: string) {
    const url = `${environment.viking.adminUrl}`;
    var updatedUrl = url.replace('/v1/', '/');
    updatedUrl = updatedUrl + 'fleets/create';
    const body = {
      "fleetId": fleetId,
      "pillage": false,
      "forcePillageEnabled": true,
      "forcePillageFromDate":  moment().format("YYYY-MM-DD").toString(),
      "startDate":  moment().format("YYYY-MM-DD").toString()
  }
    try {
      const res = await axios.post(updatedUrl, body, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        }
      })
      console.log('createFleetId 2 res', res)

      let json = await res.data
      console.log('createFleetId api response', json)

      return json

    } catch(e) {
      console.warn('Error node.fetch:', e, 'from', url)
      return null
    }
  }

  public async createDriver(fleetId: string, driverId: string, driverName: string, email: string, telephone: string, cameraInstalled: boolean, assetId: string) {
    const url = `${vikingAdminFleetsPath('drivers',fleetId)}`
    const body = {
        "driverId": driverId,
        "driverName": driverName,
        "fleet": fleetId,
        "email": email,
        "telephone": telephone,
        "assetId": assetId,
        "cameraInstalled": cameraInstalled
  }
    try {
      const res = await axios.post(url, body, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        }
      })
      console.log('createDriver 2 res', res)

      let json = await res.data
      console.log('createDriver api response', json)

      return json

    } catch(e) {
      console.warn('Error node.fetch:', e, 'from', url)
      return null
    }
  }

  public async createAsset(fleetId: string, assetId: string, assetName: string) {
    const url = `${vikingAdminFleetsPath('assets',fleetId)}`
    const body = {
        "assetId": assetId,
        "assetName": assetName,
        "fleetId": fleetId
  }
    try {
      const res = await axios.post(url, body, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        }
      })
      console.log('createAsset 2 res', res)

      let json = await res.data
      console.log('createAsset api response', json)

      return json

    } catch(e) {
      console.warn('Error node.fetch:', e, 'from', url)
      return null
    }
  }

  public async createAssetBulk(fleetId: string, assets: IResAdminAssetsRow[]) {
    const url = `${vikingAdminFleetsPath('assets/bulk/',fleetId)}`
    const body = assets.map((asset) => {
      return {
        "assetId": asset.assetId,
        "assetName": asset.assetName ? asset.assetName : "",
        "fleetId": fleetId
      }
    })
    
    try {
      const res = await axios.post(url, body, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        }
      })
      console.log('createAsset 2 res', res)

      let json = await res.data
      console.log('createAsset api response', json)

      return json

    } catch(e) {
      console.warn('Error node.fetch:', e, 'from', url)
      return null
    }
  }

  public async updateDriver(fleetId: string, driverId: string, driverName: string, email: string, telephone: string, cameraInstalled: boolean, assetId: string) {
    const url = `${vikingAdminFleetsPath('drivers/'+driverId,fleetId)}`
    const body = {
        "driverName": driverName,
        "fleet": fleetId,
        "email": email,
        "telephone": telephone,
        "assetId": assetId,
        "cameraInstalled": cameraInstalled
  }
    try {
      const res = await axios.post(url, body, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        }
      })
      console.log('updateDriver 2 res', res)

      let json = await res.data
      console.log('updateDriver api response', json)

      return json

    } catch(e) {
      console.warn('Error node.fetch:', e, 'from', url)
      return null
    }
  }

  public async updateAsset(fleetId: string, assetId: string, newFleetId: string, newAssetName: string) {
    const url = `${vikingAdminFleetsPath('assets/'+assetId,fleetId)}`
    const body = {
      "fleetId": newFleetId,
      "assetName": newAssetName
  }
    try {
      const res = await axios.post(url, body, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        }
      })
      console.log('updateAsset 2 res', res)

      let json = await res.data
      console.log('updateAsset api response', json)

      return json

    } catch(e) {
      console.warn('Error node.fetch:', e, 'from', url)
      return null
    }
  }

  public async deleteDriver(fleetId: string, driverId: string) {
    const url = `${vikingAdminFleetsPath('drivers/'+driverId,fleetId)}`
    try {
      const res = await axios.delete(url, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json'
        }
      })
      console.log('deleteDriver 2 res', res)

      let json = await res.data
      console.log('deleteDriver api response', json)

      return json

    } catch(e) {
      console.warn('Error node.fetch:', e, 'from', url)
      return null
    }
  }

  public async deleteAsset(fleetId: string, assetId: string) {
    const url = `${vikingAdminFleetsPath('assets/'+assetId,fleetId)}`
    try {
      const res = await axios.delete(url, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json'
        }
      })
      console.log('deleteAsset 2 res', res)

      let json = await res.data
      console.log('deleteAsset api response', json)

      return json

    } catch(e) {
      console.warn('Error node.fetch:', e, 'from', url)
      return null
    }
  }

  public async createVikingAsset(fleetId: string, assetId: string, assetName: string) {
    const url = `${environment.viking.vikingUrl}assets/`
    const body = [{
        "assetId": assetId,
        "assetName": assetName,
        "fleetId": fleetId
    }]
    try {
      const res = await axios.post(url, body, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        }
      })
      console.log('createAsset 2 res', res)

      let json = await res.data
      console.log('createAsset api response', json)

      return json

    } catch(e) {
      console.warn('Error node.fetch:', e, 'from', url)
      return null
    }
  }

  public async createVikingAssetBulk(assets: IResAdminAssetsRow[]) {
    const url = `${environment.viking.vikingUrl}assets/`
    const body = assets.map((asset) => {
      return {
        "assetId": asset.assetId,
        "assetName": asset.assetName ? asset.assetName : "",
        "fleetId": asset.fleetId
      }
    })
    
    try {
      const res = await axios.post(url, body, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        }
      })
      console.log('createAsset 2 res', res)

      let json = await res.data
      console.log('createAsset api response', json)

      return json

    } catch(e) {
      console.warn('Error node.fetch:', e, 'from', url)
      return null
    }
  }

  public async updateVikingAsset(fleetId: string, assetId: string, newFleetId: string, newAssetName: string) {
    const url = `${environment.viking.vikingUrl}fleet/${fleetId}/assets/${assetId}`
    const body = {
      "fleetId": newFleetId,
      "assetName": newAssetName
  }
    try {
      const res = await axios.post(url, body, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        }
      })
      console.log('updateAsset 2 res', res)

      let json = await res.data
      console.log('updateAsset api response', json)

      return json

    } catch(e) {
      console.warn('Error node.fetch:', e, 'from', url)
      return null
    }
  }

  public async deleteVikingAsset(fleetId: string, assetId: string) {
    const url = `${environment.viking.vikingUrl}fleet/${fleetId}/assets/${assetId}`
    try {
      const res = await axios.delete(url, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json'
        }
      })
      console.log('deleteAsset 2 res', res)

      let json = await res.data
      console.log('deleteAsset api response', json)

      return json

    } catch(e) {
      console.warn('Error node.fetch:', e, 'from', url)
      return null
    }
  }

  public async getAllFleets() : Promise<any> {
    this._user = this._userService.getUser()
    console.log('_getFleets 1 real, user', this._user)
    const url = environment.viking.fleetsUrl
    try {
      const res = await axios.get(url, {
        method: 'GET',
        headers: {
          // "X-Api-Key": environment.viking.apiKey,
          'Content-Type': 'application/json',
          //'ngrok-skip-browser-warning': 'true'
        }
      })
      console.log('getAllFleets  res', res)

      let json = await res.data
      console.log('getAllFleets api response', json)

      return json

    } catch(e) {
      console.warn('Error node.fetch:', e, 'from', url)
      return null
    }
  }

  public async getAllVikingFleets() : Promise<any> {
    this._user = this._userService.getUser()
    console.log('_getFleets 1 real, user', this._user)
    const url = environment.viking.vikingUrl + 'fleet'
    try {
      const res = await axios.get(url, {
        method: 'GET',
        headers: {
          // "X-Api-Key": environment.viking.apiKey,
          'Content-Type': 'application/json',
          //'ngrok-skip-browser-warning': 'true'
        }
      })
      console.log('getAllVikingFleets  res', res)

      let json = await res.data
      console.log('getAllVikingFleets api response', json)

      return json

    } catch(e) {
      console.warn('Error node.fetch:', e, 'from', url)
      return null
    }
  }

  public async checkGeofenceNameTaken(name: string) : Promise<boolean> {
    this._user = this._userService.getUser()
    const fleetId = this._user.fleetId
    console.log(`VikingService:checkGeofenceNameTaken: name ${name}`)
    const url = environment.viking.vikingUrl + `geofence/nameTaken/${name}/${fleetId}`
    try {
      const res = await axios.get(url, {
        method: 'GET',
        headers: {
          // "X-Api-Key": environment.viking.apiKey,
          'Content-Type': 'application/json'
        }
      })
      console.log(`VikingService:checkGeofenceNameTaken: res ${JSON.stringify(res)}`)

      let json = await res.data

      return json

    } catch(e) {
      console.error('VikingService:checkGeofenceNameTaken:Error axios.get:', e, 'from', url)
      return null
    }
  }

  public async createGeofence(fence: IGeofence) {
    console.log(`createGeofence res: ${JSON.stringify(fence)}`)
    const url = environment.viking.vikingUrl + `geofence`
    const body = fence
    try {
      const res = await axios.post(url, body, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        }
      })
      console.log(`createGeofence res: ${JSON.stringify(res)}`)

      let json = await res.data
      console.log('createGeofence api response', json)

      return json

    } catch(e) {
      console.warn('createGeofence: Error axios.post:', e, 'from', url)
      return null
    }
  }

  public async updateGeofence(fence: IGeofence) {
    console.log(`updateGeofence res: ${JSON.stringify(fence)}`)
    const url = environment.viking.vikingUrl + `geofence/${fence.geofenceId}`
    const body = fence
    delete body.geofenceId
    delete body.name
    try {
      const res = await axios.patch(url, body, {
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json'
        }
      })
      console.log(`updateGeofence res: ${JSON.stringify(res)}`)

      let json = await res.data
      console.log('updateGeofence api response', json)

      return json

    } catch(e) {
      console.warn('updateGeofence: Error axios.post:', e, 'from', url)
      return null
    }
  }

  public async deleteGeofence(fence: IGeofence) {
    console.log(`deleteGeofence res: ${JSON.stringify(fence)}`)
    const url = environment.viking.vikingUrl + `geofence/${fence.geofenceId}`
    const body = fence
    try {
      const res = await axios.delete(url, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json'
        }
      })
      console.log(`deleteGeofence res: ${JSON.stringify(res)}`)

      let json = await res.data
      console.log('deleteGeofence api response', json)

      return json

    } catch(e) {
      console.warn('deleteGeofence: Error axios.delete:', e, 'from', url)
      return null
    }
  }

  public async getGPSJsonData(tripId: string, relevantChunks?: string): Promise<any> {
    const params = relevantChunks ? `?relevantChunks=${relevantChunks}` : ''
    const url = `${environment.viking.vikingUrl}trip/${tripId}/downloadGps${params}`
    try {
      const res = await axios.get(url, {
        method: 'GET',
        headers: {
          // "X-Api-Key": environment.viking.apiKey,
          'Content-Type': 'application/json',
          //'ngrok-skip-browser-warning': 'true'
        }
      })
      console.log('getGPSJsonData 2 res', res)

      let json = await res.data
      return json

    } catch (e) {
      console.warn('Error node.fetch:', e, 'from', url)
      return null
    }
  }
}

