import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { MatCardModule } from '@angular/material/card';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { FlexLayoutModule } from '@angular/flex-layout';
import { TranslateModule } from '@ngx-translate/core';

import { UserTypeSelectorModule } from 'src/app/components/user-type-selector/user-type-selector.module';
import { OsmMapModule } from 'src/app/components/osm-map/osm-map.module';
import { ImageViewerDialogComponent } from './image-viewer-dialog.component';

@NgModule({
    declarations: [ImageViewerDialogComponent],
    imports: [
        CommonModule,
        FormsModule,
        MatCardModule,
        MatFormFieldModule,
        MatSelectModule,
        MatInputModule,
        MatButtonModule,
        MatDialogModule,
        FlexLayoutModule,
        UserTypeSelectorModule,
        OsmMapModule,
        TranslateModule
    ],
    exports: [TranslateModule],
})
export class ImageViewerDialogModule { }