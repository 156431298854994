<div fxFlexFill fxLayout="column" fxLayoutGap="20px" [style.width]="'100%'" [style.height]="'100%'"
    [style.paddingBottom]="'20px'" [dir]="language === 'ar-AE' ? 'rtl' : 'ltr'">
    <div fxFlex="5">
        <h1 mat-dialog-title>{{ data.eventType }}</h1>
    </div>
    <div fxFlex mat-dialog-content>
        <img #highlightImage [src]="data.imageUrl" alt="Event Image" (load)="onImageLoad($event)" class="image cover">
    </div>
    <div fxFlex="30" fxLayout="row" fxLayoutGap="20px">
        <div #mapContainer id="map"></div>
        <div fxFlex="50">
            <div [style.marginBottom]="'1em'">
                <span [style.display]="'inline-block'" [style.width]="'4em'" [style.marginRight]="'2em'"
                    [style.fontWeight]="'500'">{{ 'tripDetails.time' | translate }}:</span><span
                    [style.fontWeight]="'200'">{{ data.time }}</span>
            </div>
            <div [style.marginBottom]="'1em'">
                <span [style.display]="'inline-block'" [style.width]="'4em'" [style.marginRight]="'2em'"
                    [style.fontWeight]="'500'">{{ 'tripDetails.driver' | translate }}:</span><span
                    [style.fontWeight]="'200'">{{ data.driverName }}</span>
            </div>
            <div [style.marginBottom]="'1em'">
                <span [style.display]="'inline-block'" [style.width]="'4em'" [style.marginRight]="'2em'"
                    [style.fontWeight]="'500'">{{ 'tripDetails.assetId' | translate }}:</span><span
                    [style.fontWeight]="'200'">{{ data.assetId }}</span>
            </div>
        </div>
    </div>
    <div fxFlex="5" mat-dialog-actions fxLayoutAlign="end">
        <button [mat-dialog-close]="{delete: true}" cdkFocusInitial mat-flat-button=""
            class="mat-focus-indicator mat-flat-button mat-button-base">
            <span class="mat-button-wrapper">{{ 'tripDetails.delete' | translate }}</span>
            <span matripple="" class="mat-ripple mat-button-ripple"></span>
            <span class="mat-button-focus-overlay"></span>
        </button>
        <button [mat-dialog-close]="data" cdkFocusInitial mat-flat-button="" color="accent"
            class="mat-focus-indicator mat-flat-button mat-button-base mat-accent">
            <span class="mat-button-wrapper">{{ 'trips.ok' | translate }}</span>
            <span matripple="" class="mat-ripple mat-button-ripple"></span>
            <span class="mat-button-focus-overlay"></span>
        </button>
    </div>
</div>