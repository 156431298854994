import { Injectable } from '@angular/core';
import { Console } from 'console';
import { BehaviorSubject, Observable } from 'rxjs';
import { VikingAPIService } from 'src/app/providers/services/viking-api-service';
import { UserService } from './user-service';

@Injectable({
  providedIn: 'root'
})
export class FleetService {
  public fleetIdLabels: string[] = [];
  private vikingFleetIds: string[] = [];
  private enabledVikingFleets: any = {};

  constructor(private vapi: VikingAPIService,
    private userService: UserService,
    ) {
    //this.initializeFleets(); // Call the initialization function in the constructor
  }

  private _fleetType = new BehaviorSubject<boolean>(false);

  get fleetType$(): Observable<boolean> {
    return this._fleetType.asObservable();
  }

  setFleetType(value: boolean) {
    this._fleetType.next(value);
  }

  private async initializeFleets(): Promise<void> {
    await this.fetchVikingFleets();
    await this.fetchFleets();
  }

  private async fetchFleets(): Promise<void> {
    const fleetsResponse = await this.vapi.getAllFleets();
    this.fleetIdLabels.push(...fleetsResponse.fleets);
    console.log("this.fleetIdLabels", this.fleetIdLabels);
  }

  private async fetchVikingFleets(): Promise<void> {
    const vikingFleetsResponse = await this.vapi.getAllVikingFleets();
    this.enabledVikingFleets = vikingFleetsResponse.filter((fleet) => fleet.enabled);
    console.log("enabledVikingFleets", this.enabledVikingFleets);
    this.vikingFleetIds = this.enabledVikingFleets.map((fleet) => fleet.fleetId);
    this.fleetIdLabels.push(...this.enabledVikingFleets.map((fleet) => fleet.fleetName));
    console.log("this.fleetIdLabels", this.fleetIdLabels);
    console.log("fleet type", this.vikingFleetIds)

  }

  async getAllFleets(): Promise<string[]> {
    await this.initializeFleets(); // Fetch fleets and Viking fleets
    console.log('this.fleetIdLabels', this.fleetIdLabels);
    return this.fleetIdLabels;
  }

  isVikingFleet(fleetId: string): boolean {
    console.log("fleet type", this.fleetIdLabels)
    const res = this.vikingFleetIds.includes(fleetId);
    this.setFleetType(res);
    return res
  }

  public user: string = ''
  private fleettype: boolean;
  public fleetType: boolean;
  async setFleet(){
    await this.getUser();
    const user = await this.userService.getPortalUser(this.user);
    console.log('user', user);
    this.fleettype = this.isVikingFleet(user.fleetId);
    this.fleetType = this.fleettype;
    this.setFleetType(this.fleettype);
  }

  public async getUser(){
    const localStorageKeys = Object.keys(localStorage);
    console.log('Local storage keys:', localStorageKeys);
    let lastAuthUser = null;
    for (let i = 0; i < localStorageKeys.length; i++) {
      const key = localStorageKeys[i];
      if (key.endsWith('LastAuthUser')) {
        lastAuthUser = localStorage.getItem(key);
        break;
      }
    }
    if (lastAuthUser) {
      console.log('Last authenticated user:', lastAuthUser);
      this.user = lastAuthUser
    }
    else
    { console.log('No last authenticated user found in local storage.'); }
  }

  public getFleetIdByName(fleetName) {
    const fleet = this.enabledVikingFleets.find(f => f.fleetName === fleetName);
    return fleet ? fleet.fleetId : fleetName;
  }

  public getFleetNameById(fleetId) {
    const fleet = this.enabledVikingFleets.find(f => f.fleetId === fleetId);
    const res = fleet ? fleet.fleetName : fleetId;
    console.log('fleet nameeee', res);
    return res
  }


}
