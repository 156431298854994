import { AfterViewInit, Component, ElementRef, OnInit, ViewChild, OnDestroy, Input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import moment from 'moment';
import { BehaviorSubject, Observable, of, scan, Subscription, take } from 'rxjs';
import { ApexOptions } from 'src/@vex/components/chart/chart.component';
import { IResFleetViolationsRow, generateViolationOptions } from 'src/app/concepts/api/lightmetrics/fleets/incidents/get-fleet-violations';
import { IIncidentSummaryTableData } from 'src/app/concepts/api/lightmetrics/fleets/incidents/get-incident-summary';
import { FleetTripsAggregateValue } from 'src/app/concepts/api/lightmetrics/fleets/trips/get-aggregate-fleet-trips';
import { remapTypeIn } from 'src/app/concepts/incidents/incident-types';
import { IDateRange } from 'src/app/concepts/utils/chrono-utils';
import { FleetDataLayer, ICacheOptions } from 'src/app/providers/data-layers/fleet-data-layer';

export const shortNames = {
  'total': 'shortNames.total',
  'Distracted-Driving': 'shortNames.Distracted',
  'Drowsy-Driving-Detected': 'shortNames.Distracted',
  //'Forward-Collision-Warning': 'shortNames.FwdCollision',
  'Harsh-Acceleration': 'shortNames.Acceleration',
  'acceleration': 'shortNames.Acceleration',
  'Harsh-Braking': 'shortNames.Braking',
  'Cornering': 'shortNames.Cornering',
  'Harsh-Cornering': 'shortNames.Cornering',
  'Max-Speed-Exceeded': 'shortNames.SpeedViolation',
  'MaxSpeedExceeded': 'shortNames.SpeedViolation',
  'Speed-Limit-Violation': 'shortNames.Speeding',
  'Stop-Sign-Violation': 'shortNames.StopSign',
  'Tailgating': 'shortNames.Tailgating',
  'Tailgating-Detected': 'shortNames.Tailgating',
  'Tail-Gating-Detected': 'shortNames.Tailgating',
  'Lane-Drift-Found': 'shortNames.LaneDrift',
  'Traffic-Speed-Violated': 'shortNames.SpeedViolation',
  'Cellphone-Distracted-Driving': 'shortNames.Distracted',
  'Roll-Over-Detected': 'shortNames.Rollover',
  'cornering': 'shortNames.Cornering',
  'braking': 'shortNames.Braking',
  'Driver-SeatBelt-Non-Compliance': 'shortNames.Driver',
  'Passenger-SeatBelt-Non-Compliance': 'shortNames.Passenger',
  'Camera-Lens-Covered': 'shortNames.Camera',
  'Uniform-Non-Compliance': 'shortNames.Uniform',
  'Smoking-Distracted-Driving': 'shortNames.Smoking',

}

@Component({
  selector: 'vex-incident-summary',
  templateUrl: './incident-summary.component.html',
  styleUrls: ['./incident-summary.component.scss']
})
export class IncidentSummaryComponent implements OnInit, AfterViewInit {
  @ViewChild('card', { static: true }) card:ElementRef
  private resizeObserver: ResizeObserver
  public defaultOptions: ApexOptions = {
    series: [],
    labels: [],
    chart: {
      id: 'incident-summary',
      // width: 380,
      type: 'bar',  // what chart is the trend chart?
      foreColor: '#000',
      toolbar: {
        show: false
      },
      height: '100%'
    },
    tooltip: {
      theme: 'light'
    },
    theme: {
      mode: 'light',
    },
    // plotOptions: {
    //   pie: {
    //     startAngle: -90,
    //     endAngle: 270
    //   }
    // },
    dataLabels: {
      enabled: true
    },
    fill: {
      type: 'gradient',
    },
    // responsive: [{
    //   breakpoint: 480,
    //   options: {
    //     chart: {
    //       width: 200
    //     },
    //     legend: {
    //       position: 'bottom'
    //     }
    //   }
    // }]
  };

  private _dateRange: IDateRange
  // @Input() set dateRange(value: IDateRange) {
  //   this._dateRange = value
  //   this._runQuery()
  // }

  public data: FleetTripsAggregateValue
  @Input() set incidentData(_data: FleetTripsAggregateValue) {
    this.data = _data
    if(_data) {
      this._extractIncidentPanelData()
    }
  }

  public options: ApexOptions = this.defaultOptions
  private _subs: Subscription[] = []
  private _increment: BehaviorSubject<number> = new BehaviorSubject<number>(0);
  public loaded = this._increment.pipe(scan((previous, increment) => previous + increment))
  public incidentsTableData$ : Observable<ApexOptions> = of(this.defaultOptions)
  private _numResourcesToLoad: number = 1
  public violationsData: IResFleetViolationsRow[]
  public language: string = this.translate.currentLang

  constructor(
    public fdl: FleetDataLayer,
    private translate: TranslateService
  ) {
    this.translate.onLangChange.subscribe((event) => {
      this.language = event.lang;
    });
  }

  ngAfterViewInit(): void {
    // this.resizeObserver =
    //   new ResizeObserver(() => {
    //     this.incidentsTableData$ = of({...this.options, chart: {...this.options.chart, 'height': `${this.card.nativeElement.offsetHeight}px`}});
    //   });
    // this.resizeObserver.observe(this.card.nativeElement);
  }

  private disconnectResizeObserver() {
    // this.resizeObserver.unobserve(this.card.nativeElement);
    // this.resizeObserver.disconnect();
  }

  private violationsDatasource = ((options: ICacheOptions) : Observable<IResFleetViolationsRow[]> => {
    return this.fdl.getViolations(generateViolationOptions(options))
  });

  ngOnInit(): void {
  }


  // private _runQuery(): void {
  //   this._numResourcesToLoad = 1

  //   if(this._subs?.length > 0) {
  //     this._subs.forEach(sub => sub.unsubscribe())
  //     this._subs = []
  //   }

  //   this._increment = new BehaviorSubject<number>(0);
  //   this.loaded = this._increment.pipe(scan((previous, increment) => previous + increment))

  //   this._subs.push(this.loaded.subscribe(loadedCounter => {
  //     if(loadedCounter === this._numResourcesToLoad) {
  //       this._extractIncidentPanelData()
  //     }
  //   }))

  //   this.fdl.addCache<IResFleetViolationsRow>('violations', this.violationsDatasource, {
  //     after: this._dateRange.from,
  //     before: this._dateRange.to,
  //   }, true)

  //   this.fdl.getCacheType<IResFleetViolationsRow>('violations')
  //     .getPage(0)
  //     .pipe(take(1))
  //     .subscribe(res => {
  //       this.violationsData = res
  //       this._increment.next(1)
  //     })
  // }

  ngOnDestroy(): void {
    this.disconnectResizeObserver();
    this._subs.forEach(sub => sub.unsubscribe())
  }

  private _extractIncidentPanelData() {
    try {
      console.log('DATA', this.data);

      // Handle rawData extraction here, if needed

      const rawData = this.data.eventCount;
      delete rawData.total;

      const keys = Array.from(new Set(Object.keys(rawData).map(key => this._shortName(key))));

      // Handle shortData transformation here, if needed

      const shortData = Object.keys(rawData).reduce((acc, key) => {
        try {
          if (rawData[key] > 0) {
            const shortKey = this.translate.instant(this._shortName(key));
            acc[shortKey] = acc[shortKey] ? acc[shortKey] + rawData[key] : rawData[key];
          }
        } catch (error) {
          console.error('Error processing shortData:', error);
          // Handle the error as needed
        }
        return acc;
      }, {});

      // Handle seriesData transformation here, if needed

      const seriesData = Object.keys(shortData).reduce((acc, key) => {
        try {
          if (shortData[key] > 0) {
            acc.push({
              x: key,
              y: shortData[key]
            });
          }
          acc.sort((a, b) => b.y - a.y);
        } catch (error) {
          console.error('Error processing seriesData:', error);
          // Handle the error as needed
        }
        return acc;
      }, []);

      const series = [{
        data: seriesData
      }];

      this.options = { ...this.defaultOptions, series };
      this.incidentsTableData$ = of(this.options);
      this._increment.next(1);
    } catch (error) {
      console.error('An error occurred:', error);
      // Handle the error at a higher level if needed
    }
  }

  private _shortName(key: string) : string {
    console.log('shortname conv in/out:', key, shortNames[remapTypeIn(key)])
    return shortNames[remapTypeIn(key)]
  }
}
