import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';
import { MatPaginatorModule } from '@angular/material/paginator';
import { RouterModule } from '@angular/router';
import { ContentLoaderModule } from '@ngneat/content-loader';
import { NgxTippyModule } from 'ngx-tippy-wrapper';
import { VjsPlayerModule } from 'src/@vex/components/videojs/vjs-player.module';
import { IncidentSelectorModule } from '../../incident-selector/incident-selector.module';
import { VideoPlayerDialogModule } from './dialogs/video-player/video-player-dialog.module';
import { ImageViewerDialogModule } from './dialogs/image-viewer/image-viewer-dialog.module';
import { HighlightsComponent } from './highlights.component';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  declarations: [HighlightsComponent],
  imports: [
    CommonModule,
    RouterModule,
    MatCardModule,
    MatIconModule,
    MatPaginatorModule,
    FlexLayoutModule,
    IncidentSelectorModule,
    NgxTippyModule,
    ContentLoaderModule,
    VjsPlayerModule,
    VideoPlayerDialogModule,
    ImageViewerDialogModule,
    TranslateModule
  ],
  exports: [
    HighlightsComponent,
    TranslateModule
  ]
})
export class HighlightsModule {
}
