<div class="card overflow-hidden w-full flex flex-col">
  <!-- <div class="border-b py-4 px-6 flex items-center">

    <button mat-icon-button type="button">
      <mat-icon class="text-secondary" svgIcon="mat:cloud_download"></mat-icon>
    </button>

    <button mat-icon-button type="button">
      <mat-icon class="text-secondary" svgIcon="mat:more_horiz"></mat-icon>
    </button>
  </div> -->

  <div class="overflow-hidden">
    <ng-container *ngIf="!isLoaded; else content">
      <div class="spinner-container">
        <div class="spinner">
          <mat-progress-spinner mode="indeterminate"></mat-progress-spinner>
        </div>
      </div>
    </ng-container>
    <ng-template #content>
      <!--- Note that these columns can be defined in any order.
              The actual rendered columns are set as a property on the row definition" -->

      <!-- Model Properties Column -->
      <table [dataSource]="dataSource" class="w-full" mat-table multiTemplateDataRows>
        <ng-container *ngFor="let column of columns">
          <ng-container *ngIf="column.type === 'text'" [matColumnDef]="column.property">
            <th *matHeaderCellDef mat-header-cell> {{ column.label }}</th>
            <td *matCellDef="let row" [ngClass]="column.cssClasses" mat-cell><span
                [ngxTippy]="row['tooltips']?.[column.property]" [tippyProps]="tippyProps">{{ row[column.property]
                }}</span></td>
          </ng-container>
          <ng-container *ngIf="column.type === 'clickable-text'" [matColumnDef]="column.property">
            <th *matHeaderCellDef mat-header-cell> {{ column.label }}</th>
            <td *matCellDef="let row" [ngClass]="column.cssClasses" mat-cell><span
                [style.cursor]="'pointer'" (click)="row.callback()" [ngxTippy]="row['tooltips']?.[column.property]" [tippyProps]="tippyProps">{{ row[column.property]
                }}</span></td>
          </ng-container>
          <ng-container *ngIf="column.type === 'incident'" [matColumnDef]="column.property">
            <th *matHeaderCellDef mat-header-cell> {{ column.label }}</th>
            <td *matCellDef="let row" [ngClass]="column.cssClasses" mat-cell>
              <span class="dot-icon {{row.type}}"></span>
              <span class="title">{{ row[column.property] }}</span>
            </td>
          </ng-container>
          <ng-container *ngIf="column.type === 'badge'" [matColumnDef]="column.property">
            <th *matHeaderCellDef mat-header-cell> {{ column.label }}</th>
            <td *matCellDef="let row" [ngClass]="column.cssClasses" mat-cell>
              <div *ngIf="row[column.property] === 'ready'" class="w-3 h-3 rounded-full bg-green cursor-pointer"
                matTooltip="Ready to ship"></div>
              <div *ngIf="row[column.property] === 'pending'" class="w-3 h-3 rounded-full bg-orange cursor-pointer"
                matTooltip="Pending Payment"></div>
              <div *ngIf="row[column.property] === 'warn'" class="w-3 h-3 rounded-full bg-red cursor-pointer"
                matTooltip="Missing Payment"></div>
            </td>
          </ng-container>
          <ng-container *ngIf="column.type === 'icon'" [matColumnDef]="column.property">
            <th *matHeaderCellDef mat-header-cell> {{ column.label }}</th>
            <td *matCellDef="let row" [ngClass]="column.cssClasses" mat-cell>
              <mat-icon role="img" class="mat-icon notranslate material-icons mat-icon-no-color" aria-hidden="true"
                data-mat-icon-type="font">{{row[column.property]}}</mat-icon>
            </td>
          </ng-container>
          <ng-container *ngIf="column.type === 'actions'" [matColumnDef]="column.property">
            <th *matHeaderCellDef mat-header-cell> {{ column.label }}</th>
            <td *matCellDef="let row" [ngClass]="column.cssClasses" mat-cell>
              <ng-container *ngFor="let icon of row[column.property]">
                <mat-icon [ngxTippy]="actions[icon].tooltip" [tippyProps]="tippyProps"
                  (click)="onActionSelected(icon, row)" role="img"
                  class="mat-icon notranslate material-icons mat-icon-no-color" color="accent"
                  data-mat-icon-type="font">{{actions[icon].icon}}</mat-icon>
              </ng-container>
            </td>
          </ng-container>
          <ng-container *ngIf="column.type === 'image'" [matColumnDef]="column.property">
            <th *matHeaderCellDef mat-header-cell> {{ column.label }}</th>
            <td *matCellDef="let row" [ngClass]="column.cssClasses" mat-cell>
              <img role="img" class="mat-icon notranslate material-icons mat-icon-no-color"
                [src]="row[column.property]">
            </td>
          </ng-container>
          <ng-container *ngIf="column.type === 'accent-button'" [matColumnDef]="column.property">
            <th *matHeaderCellDef mat-header-cell> {{ column.label }}</th>
            <td *matCellDef="let row" [ngClass]="column.cssClasses" mat-cell>
              <div class="column-button">
                <button (click)="onButtonClick(column, row)" type="button" mat-flat-button="" color="accent"
                  class="mat-focus-indicator mat-flat-button mat-button-base mat-accent">
                  <span class="mat-button-wrapper"> {{column.property}} </span>
                  <span matripple="" class="mat-ripple mat-button-ripple"></span>
                  <span class="mat-button-focus-overlay"></span>
                </button>
              </div>
            </td>
          </ng-container>
          <ng-container *ngIf="column.type === 'status'" [matColumnDef]="column.property">
            <th *matHeaderCellDef mat-header-cell> {{ column.label }}</th>
            <td *matCellDef="let row" [ngClass]="column.cssClasses" mat-cell>
              <mat-chip-list>
                <mat-chip role="option" mattooltipposition="left"
                  [style]="{'background-color': row[column.property].color, 'line-height': '14px'}"
                  class="mat-chip mat-focus-indicator mat-primary mat-standard-chip" tabindex="-1">
                  <div class="mat-chip-ripple"></div>
                  {{row[column.property].label}}
                </mat-chip>
              </mat-chip-list>
            </td>
          </ng-container>
          <ng-container *ngIf="column.type === 'innerHTML'" [matColumnDef]="column.property">
            <th *matHeaderCellDef mat-header-cell> {{ column.label }}</th>
            <td *matCellDef="let row" [ngClass]="column.cssClasses" mat-cell>
              <div [innerHTML]="row[column.property]"></div>
            </td>
          </ng-container>
        </ng-container>

        <ng-container *ngIf="expandRowOnToggle" matColumnDef="expandedDetail">
          <td mat-cell *matCellDef="let element" [attr.colspan]="visibleColumns.length">
            <div class="expanded-row" [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'">
              <div class="er-content" fxFlex fxLayout="row" fxLayoutAlign="start center" fxFlexFullWidth>
                <ng-container *ngTemplateOutlet="template, context: context"></ng-container>
              </div>
            </div>
          </td>
        </ng-container>

        <tr *matNoDataRow class="mat-row">
          <div fxLayout="row" fxLayoutGap="20px" fxLayoutAlign="start center" class="app-alert">
            <mat-icon role="img" class="mat-icon notranslate alert-icon material-icons mat-icon-no-color"
              data-mat-icon-type="font">info_outline</mat-icon>
            <span class="alert-text" [style.font-size]="'.8em'">{{noDataMessage}}</span>
          </div>
        </tr>
        <tr *matHeaderRowDef="visibleColumns" mat-header-row></tr>
        <tr *matRowDef="let row; columns: visibleColumns;" mat-row class="default-row">
        </tr>
        <ng-container *ngIf="expandRowOnToggle">
          <tr *matRowDef="let detail; columns: ['expandedDetail']" mat-row class="expanded-row">
          </tr>
        </ng-container>
      </table>
    </ng-template>
  </div>

  <!-- <mat-paginator [pageSize]="pageSize" class="paginator"></mat-paginator> -->
  <mat-paginator *ngIf="!hidePagination" [style.opacity]="isLoaded ? '1' : '0'" [pageSize]="paginationResponse?.pageSize || 0"
    [length]="paginationResponse?.length || 0" (page)="onPageEvent($event)" class="paginator">
  </mat-paginator>

</div>