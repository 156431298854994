
export enum ETripEventType {
  'Trip-Start' = 'Trip Start',
  'Trip-Finish' = 'Trip Finish'
}

export enum EIncidentType {
  'All' = 'violations.all',
  'Cellphone-Distracted-Driving' = 'violations.CellphoneDistractedDriving', // #NESTLE - Unbundling event
  'Distracted-Driving' = 'violations.DistractedDriving',
  'Drowsy-Driving-Detected' = 'violations.DrowsyDrivingDetected',
  //'Forward-Collision-Warning' = 'violations.ForwardCollisionWarning',
  'Harsh-Acceleration' = 'violations.HarshAcceleration',
  'acceleration' = 'violations.HarshAcceleration',
  'Harsh-Braking' = 'violations.HarshBraking',
  'Cornering' = 'violations.HarshCornering',
  'Max-Speed-Exceeded' = 'violations.MaxSpeedExceeded',
  'Speed-Limit-Violation' = 'violations.SpeedLimitViolation',
  'Stop-Sign-Violation' = 'violations.StopSignViolation',
  'Tail-Gating-Detected' = 'violations.Tailgating',
  'Traffic-Speed-Violated' = 'violations.TrafficSpeedViolated',
  'Smoking-Distracted-Driving' = 'violations.SmokingDistractedDriving',
  'Driver-SeatBelt-Non-Compliance' = 'violations.DriverSeatBeltViolation',
  'Passenger-SeatBelt-Non-Compliance' = 'violations.PassengerSeatBeltViolation',
  //'Lizard-Eye-Distracted-Driving' = 'violations.DistractedDriving',
  'Roll-Over-Detected' = 'violations.RollOverDetected',
  'cornering' = 'violations.HarshCornering',
  'braking' = 'violations.HarshBraking',
  'Camera-Lens-Covered' = 'violations.CameraLensCovered',
  'Uniform-Non-Compliance' = 'violations.UniformNonCompliance',

  //'Possible-Collision' = 'Possible-Collision',
  'Lane-Drift-Found' = 'violations.LaneDriftFound',
}

export const driverFacingIncidents = [
  'Cellphone-Distracted-Driving',
  'Distracted-Driving',
  'Drowsy-Driving-Detected',
  'Smoking-Distracted-Driving',
  'Driver-SeatBelt-Non-Compliance',
  'Passenger-SeatBelt-Non-Compliance',
  'Camera-Lens-Covered',
  'Uniform-Non-Compliance'
]

const roadFacingIncidents = [
  'Harsh-Acceleration',
  'Harsh-Braking',
  'Cornering',
  'Max-Speed-Exceeded',
  'Speed-Limit-Violation',
  'Stop-Sign-Violation',
  'Tail-Gating-Detected',
  'Traffic-Speed-Violated',
  'Roll-Over-Detected',
  'Lane-Drift-Found'
]

export const midSizedNames = {
  "All": "midSizedNames.all",
  "braking": "midSizedNames.HarshBraking",
  "acceleration": "midSizedNames.HarshAcceleration",
  "cornering": "midSizedNames.Cornering",
  "Distracted-Driving": "midSizedNames.DistractedDriving",
  "Drowsy-Driving-Detected": "midSizedNames.DrowsyDrivingDetected",
  "Harsh-Acceleration": "midSizedNames.HarshAcceleration",
  "Harsh-Braking": "midSizedNames.HarshBraking",
  "Harsh-Cornering": "midSizedNames.HarshCornering",
  "Max-Speed-Exceeded": "midSizedNames.MaxSpeedExceeded",
  "Speed-Limit-Violation": "midSizedNames.SpeedLimitViolation",
  "Tail-Gating-Detected": "midSizedNames.Tailgating",
  "Stop-Sign-Violation": "midSizedNames.StopSignViolation",
  "Traffic-Speed-Violated": "midSizedNames.TrafficSpeedViolated",
  "Forward-Collision-Warning": "midSizedNames.ForwardCollisionWarning",
  "TailGatingDetected": "midSizedNames.TailGatingDetected",
  "Cornering": "midSizedNames.Cornering",
  'Cellphone-Distracted-Driving': "midSizedNames.CellphoneDistractedDriving",
  "Driver-SeatBelt-Non-Compliance": "midSizedNames.DriverSeatBeltViolation",
  "Camera-Lens-Covered": "midSizedNames.CameraLensCovered",
  "Passenger-SeatBelt-Non-Compliance": "midSizedNames.PassengerSeatBeltViolation",
  "Roll-Over-Detected": "midSizedNames.RollOverDetected",
  "Smoking-Distracted-Driving": "midSizedNames.SmokingDistractedDriving",
  'Uniform-Non-Compliance': 'midSizedNames.UniformNonCompliance',
  'Lane-Drift-Found': 'midSizedNames.LaneDriftFound',
};

export const convertToMidName = (incidentType: string): string | undefined => {
  console.log("incidentType and midSizedName", incidentType, midSizedNames[incidentType])
  return midSizedNames[incidentType]
}

export const convertToEIncidentType = (midName: string): EIncidentType | undefined => {
  const entry = Object.entries(midSizedNames).find(([key, value]) => value === midName);
  return entry ? EIncidentType[entry[0] as keyof typeof EIncidentType] : undefined;
}


export const remapTypeIn = (type: string) : string => {
  switch(type) {
    case "Cornering": return "Cornering"
    case "TailGating-Detected":
    case "Tailgating": return "Tailgating"
    case "MaxSpeedExceeded": return "Max-Speed-Exceeded"
    case "Cellphone-Distracted-Driving": return "Cellphone-Distracted-Driving"
    case "Drinking-Distracted-Driving": return "Distracted-Driving"
    default: return type
  }
}

export interface IOutgoingEventType {
  [key:string] : string
}

export const outgoingEventTypeMap: IOutgoingEventType = {
  'violations.all': null,
  'violations.Tailgating': 'Tail-Gating-Detected',
  'violations.TailGatingDetected': 'Tail-Gating-Detected',
  'violations.DistractedDriving': 'Distracted-Driving',
  'violations.ForwardCollisionWarning': 'Forward-Collision-Warning',
  'violations.HarshAcceleration': 'Harsh-Acceleration',
  'violations.HarshBraking': 'Harsh-Braking',
  'violations.HarshCornering': 'Cornering',
  'violations.Cornering': 'Cornering',
  'violations.SpeedLimitViolation': 'Speed-Limit-Violation',
  'violations.StopSignViolation': 'Stop-Sign-Violation',
  'violations.MaxSpeedExceeded': 'Max-Speed-Exceeded',
  'violations.DrowsyDrivingDetected': 'Drowsy-Driving-Detected',
  'violations.CellphoneDistractedDriving': 'Cellphone-Distracted-Driving', // #NESTLE - Unbundling event
  'violations.TrafficSpeedViolated': 'Traffic-Speed-Violated',
  'violations.DriverSeatBeltViolation': 'Driver-SeatBelt-Non-Compliance',
  'violations.PassengerSeatBeltViolation': 'Passenger-SeatBelt-Non-Compliance',
  'violations.CameraLensCovered': 'Camera-Lens-Covered',
  'violations.DeviceOffOrDisconnected': 'Device-Off-Or-Disconnected',
  'violations.RollOverDetected': 'Roll-Over-Detected',
  //'Possible-Collision' : 'Possible-Collision',
  'violations.SmokingDistractedDriving': 'Smoking-Distracted-Driving', // #NESTLE - New event
  'violations.CameraBlocked': 'Camera-Blocked', // #NESTLE - New event
}

