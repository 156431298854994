import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { EIncidentType, convertToEIncidentType, convertToMidName } from 'src/app/concepts/incidents/incident-types';
import { getIncidentPickerData, getLabelData, IIncidentTypePickerData } from 'src/app/concepts/incidents/incidents';
import { EDuration } from 'src/app/concepts/utils/chrono-utils';

@Component({
  selector: 'vex-incident-selector',
  templateUrl: './incident-selector.component.html',
  styleUrls: ['./incident-selector.component.scss']
})
export class IncidentSelectorComponent implements OnInit {

  @Output() change: EventEmitter<any> = new EventEmitter();
  public labelData: IIncidentTypePickerData
  public selected: string ;
  public language: string = this.translate.currentLang;

  constructor(private translate: TranslateService ) { 
    this.translate.onLangChange.subscribe((event) => {
      this.language = event.lang;
    });
  }

  ngOnInit(): void {
    this.labelData = getIncidentPickerData();
    this.selected = 'violations.all'
  }

  onChange(event: any) : void {
    this.change.emit(convertToEIncidentType(event))
  }

  
  
  list: string[] = Object.keys(EIncidentType).map(key => convertToMidName(key)).sort((a, b) => a.localeCompare(b));
  public labels  = [... new Set(this.list)];



}
